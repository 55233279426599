<template>
    <div>
        <div class="page-header page-header-sticky page-header-sticky-open">
            <div class="page-header-content page-header-dark mb-0 header-elements-md-inline">

                <div class="page-header-info flex-1 py-3 text-uppercase">
                    <div class="d-flex justify-content-between">
                        <div>
                            <div class="d-flex justify-content-between align-items-center">
                                <h5 class="phi-main font-weight-semibold mb-2 pb-1">
                                    <span>{{row.ap_fullname||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No Rekam Medis">{{row.ap_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left">{{rowReg.aur_reg_code||"-"}}</span>
                                    <span class="ml-2 pl-2 border-left" v-b-tooltip.hover title="No KTP">{{row.ap_nik||"-"}}</span>
                                </h5>
                            </div>
                            <div class="phi-sub-info d-flex">
                                <div class="phi-info d-flex flex-column">
                                    <small>Tanggal Lahir</small>
                                    <strong class="font-weight-semibold">{{row.ap_dob | moment("DD MMM YYYY")}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Usia</small>
                                    <strong class="font-weight-semibold">{{row.ap_usia_with_ket || "-"}}<br />({{row.ap_gol_usia||"-"}})</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Jenis Kelamin</small>
                                    <strong class="font-weight-semibold">{{row.cg_label}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Opsi Pembayaran</small>
                                    <strong class="font-weight-semibold">{{rowReg.mcp_name || "-"}}</strong>
                                </div>
                                <div class="phi-info d-flex flex-column pl-2 ml-3">
                                    <small>Agama</small>
                                    <strong class="font-weight-semibold">{{row.ap_agama_text || "-"}}</strong>
                                </div>
                            </div>
                        </div>
                        
                        <div class="phi-info d-flex flex-column col-md-3 ml-auto">
                            <small>Dokter</small>
                            <h5 class="phi-main font-weight-semibold mb-0">
                                <span>{{rowReg.bu_full_name||"-"}}</span>
                            </h5>
                            <div class="mb-2">
                                <strong class="font-weight-semibold">{{rowReg.mpo_name||"-"}}</strong>
                            </div>
                            <div>
                                <i class="icon-phone2 mr-1 align-middle"></i>
                                <span class="align-middle">{{rowReg.bu_phone||"-"}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="javascript:;" class="btn btn-sm btn-primary page-header-sticky-toggle">
            <i class="icon-arrow-up5 align-middle"></i>
            <span class="align-middle">Sembunyikan Informasi Pasien</span>
            </a>
        </div>
        <div class="content">
            <div class="card">
            <div class="card-header bg-white">
                <h6 class="card-title font-weight-semibold" v-if="!isDetail">Buat Rekonsiliasi Obat</h6>
                <h6 class="card-title font-weight-semibold" v-else>Rekonsiliasi Obat</h6>
            </div>
            <validation-observer ref="VFormSubmit">
            <b-form @submit.prevent="doSubmit">
            <div class="card-body">
                <template v-if="!isDetail">
                    <div class="form-group">
                    <label for="">Daftar Obat-Obatan Pasien</label>
                    
                        <validation-observer ref="VFormObat">
                        <div class="table-responsive">
                        <b-table
                            bordered
                            striped
                            small
                            hover
                            :fields="farmTableFields"
                            table-class="w-auto"
                            :items="data"
                        >
                            <template #cell(aksi)="rowTable">
                                <a @click="removeObat(rowTable.item,rowTable.index)" href="javascript:;"
                                        class="btn btn-sm alpha-danger border-danger text-danger-800 btn-icon rounded-round"
                                        data-popup="tooltip" v-b-tooltip.hover title="Hapus"><i class="icon-trash"></i></a>
                            </template>

                            <template #cell(aurfr_date)="rowTable">
                                {{rowTable.value | moment("DD MMMM YYYY")}}
                            </template>

                            
                            <template #cell(aurfr_nama)="rowTable">
                                <b-form-input v-model="data[rowTable.index]['aurfr_nama']"/>
                                <VValidate :name="'Rute Pemberian '+(rowTable.index+1)" v-model="data[rowTable.index].aurfr_nama" :rules="{required : 1}" />
                            </template>
                            
                            <template #cell(aurfr_rute)="rowTable">
                                <b-form-input v-model="data[rowTable.index]['aurfr_rute']"/>
                                <VValidate :name="'Rute Pemberian '+(rowTable.index+1)" v-model="data[rowTable.index].aurfr_rute" :rules="{required : 1}" />
                            </template>
                                                    
                            <template #cell(aurfr_perubahan_aturan_pakai)="rowTable">
                                <b-form-input v-model="data[rowTable.index]['aurfr_perubahan_aturan_pakai']"/>
                            </template>



                            <template #cell(aurfr_waktu_pemberian)="rowTable">
                                <v-select
                                    placeholder="Pilih Item"
                                    v-model="data[rowTable.index].aurfr_waktu_pemberian"
                                    :options="Config.mr.tindakLanjutFarmasi"
                                    label="text"
                                    :reduce="v=>v.value"
                                />
                                <VValidate :name="'Waktu Pemberian '+(rowTable.index+1)" v-model="data[rowTable.index].aurfr_waktu_pemberian" :rules="{required : 1}" />
                            </template>
                            
                            <template #cell(aurfr_tindak_lanjut_pemberian)="rowTable">
                                <v-select
                                    placeholder="Pilih Item"
                                    v-model="data[rowTable.index].aurfr_tindak_lanjut_pemberian"
                                    :options="Config.mr.tindakLanjutFarmasi"
                                    label="text"
                                    :reduce="v=>v.value"
                                />
                                <VValidate :name="'Tindak Lanjut Pemberian '+(rowTable.index+1)" v-model="data[rowTable.index].aurfr_tindak_lanjut_pemberian" :rules="{required : 1}" />
                            </template>


                            <template #row-details="rowTable">
                                <div class="card mb-0" v-if="!editRacikanDokter">
                                    <div class="card-body">
                                        <h5 class="card-title font-weight-semibold">Komposisi Racikan</h5>
                                        <p>{{rowRacikan.aurfr_jumlah||"-"}} {{rowRacikan.aurfr_satuan||"-"}} {{rowRacikan.mrd_nama_racikan||"-"}} terdiri dari:</p>
                                        <table class="table table-striped table-sm table-hover table-bordered">
                                            <thead>
                                                <tr>
                                                    <th width="40">#</th>
                                                    <th>Nama Obat</th>
                                                    <th>Qty</th>
                                                    <th>Frekuensi</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(v,k) in rowRacikan.aurfr_racikan" :key="k+'rowracik'">
                                                    <td>{{k+1}}</td>
                                                    <td>{{getObat(v.value)||"-"}}</td>
                                                    <td>{{v.jumlah||"-"}} {{v.satuan_jumlah||"-"}}</td>
                                                    <td>{{v.dosis||"-"}} {{v.satuan_dosis||"-"}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="card-footer">
                                        <a href="javascript:;" @click="openFormRacikan = true;openRacikan = false" class="btn btn-link">
                                        <div class="icon-pencil7"></div>
                                        Ubah Komposisi Racikan
                                        </a>
                                        <button @click="rowTable.toggleDetails" class="btn btn-success btn-labeled btn-labeled-left"><b><i class="icon-checkmark"></i></b>Mengerti</button>
                                    </div>
                                </div>
                            </template>
                            <template #cell(aurfr_jumlah)="rowTable">
                                <div class="input-group">
                                    <b-form-input type="text" v-model="data[rowTable.index]['aurfr_jumlah']" :formatter="$parent.number" class="form-control" id="itemCount" />
                                </div>
                                <VValidate :name="'Jumlah '+(rowTable.index+1)" v-model="data[rowTable.index]['aurfr_jumlah']" :rules="{required : 1}" />
                            </template>
                            <template #cell(aurfr_frekuensi)="rowTable">
                                <span class="d-flex">
                                    <v-select style="flex:1;" placeholder="Pilih Frekuensi" v-model="data[rowTable.index].aurfr_frekuensi" :options="mDosis" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                    <a href="javascript:;" @click="data[rowTable.index]['aurfr_frekuensi'] = 99999" v-b-tooltip.hover="'Pilih Lainnya'" class="btn btn-sm btn-icon alpha-info border-info btn-plus-other"><i class="icon-plus-circle2"></i></a>
                                </span>
                                <VValidate :name="'Frekuensi '+(rowTable.index+1)" v-model="data[rowTable.index]['aurfr_frekuensi']" :rules="{required : 1}" />
                                <div v-if="data[rowTable.index]['aurfr_frekuensi'] == 99999">
                                    <b-form-input type="text" v-model="data[rowTable.index]['aurfr_frekuensi_lainnya']" class="form-control" id="itemCount" />
                                    <VValidate :name="'Frekuensi Lainnya '+(rowTable.index+1)" v-model="data[rowTable.index]['aurfr_frekuensi_lainnya']" :rules="{required : 1}" />
                                </div>
                            </template>
                            <template #custom-foot>
                                <tr>
                                    <td class="text-center" colspan="99">
                                        <b-button @click="openObat()" variant="outline-primary" class="btn alpha-blue">
                                            <i class="icon-plus2 mr-1 align-middle"></i>
                                            <span class="align-middle">Tambah Resep</span>
                                        </b-button>
                                    </td>
                                </tr>
                            </template>
                        </b-table>
                        </div>
                        </validation-observer>



                    </div>
                    <div class="form-group">
                    <table class="table table-bordered table-sm">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Penilaian</th>
                            <th>Parameter</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>Belum mencapai tanggal kedaluwarsa / <em>Beyond use date</em> (obat racikan)</td>
                            <td>
                            <div>
                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                v-model="row['auro_p1']" />
                                <VValidate :name="'Penilaian 1'" v-model="row['auro_p1']" :rules="{required : 1}" />
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Penyimpanan obat baik (masih terbungkus dalam kemasan obat)</td>
                            <td>
                            <div>
                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                v-model="row['auro_p2']" />
                                <VValidate :name="'Penilaian 2'" v-model="row['auro_p2']" :rules="{required : 1}" />
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Tidak ada perubahan bentuk fisik sediaan obat</td>
                            <td>
                            <div>
                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                v-model="row['auro_p3']" />
                                <VValidate :name="'Penilaian 3'" v-model="row['auro_p3']" :rules="{required : 1}" />
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Label etiket obat terlihat jelas di masing-masing obat</td>
                            <td>
                            <div>
                                <b-form-radio-group :options="Config.mr.yesNoOptV2"
                                v-model="row['auro_p4']" />
                                <VValidate :name="'Penilaian 4'" v-model="row['auro_p4']" :rules="{required : 1}" />
                            </div>
                            </td>
                        </tr>
                        <tr class="table-info">
                            <td colspan="2"><strong>Kesimpulan penilaian kelayakan obat</strong></td>
                            <td>
                            <div>
                                <b-form-radio-group :options="Config.mr.layakTidakLayakOpt"
                                v-model="row['auro_kesimpulan']" />
                                <VValidate :name="'Kesimpulan'" v-model="row['auro_kesimpulan']" :rules="{required : 1}" />
                            </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </template>
                <template v-else>
                    <div class="form-group">
                        <label for="">Daftar Obat-Obatan Pasien</label>
                        <table class="table table-bordered table-sm">
                        <thead>
                            <tr>
                            <th>Tanggal</th>
                            <th>Nama</th>
                            <th>Jumlah</th>
                            <th>Frekuensi</th>
                            <th>Rute</th>
                            <th>Waktu Pemberian Terakhir</th>
                            <th>Tindak Lanjut Rekonsiliasi Obat</th>
                            <th>Perubahan Aturan Pakai</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(v,k) in (data||[])" :key="'resepDokter'+k">
                             <td>
                                <div class="result_tab">
                                <p>{{v.aurfr_date | moment("DD MMMM YYYY")}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                {{v.aurfr_nama||"-"}}
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <p>{{v.aurfr_jumlah||"-"}}</p>
                                </div>
                            </td>
                            <td>
                                <div class="result_tab">
                                <p>
                                    <span v-if="v.aurfr_frekuensi !== 99999">{{v.mdo_name || "-"}}</span>
                                    <span v-else>{{v.aurfr_frekuensi_lainnya || "-"}}</span>
                                </p>
                                </div>
                            </td>
                            
                            <td>
                                <div class="result_tab">
                                <p>{{v.aurfr_rute || "-"}}</p>
                                </div>
                            </td>
                            
                            <td>
                                <div class="result_tab">
                                <p>{{v.aurfr_waktu_pemberian || "-"}}</p>
                                </div>
                            </td>
                            
                            <td>
                                <div class="result_tab">
                                <p>{{v.aurfr_tindak_lanjut_pemberian || "-"}}</p>
                                </div>
                            </td>
                            
                            <td>
                                <div class="result_tab">
                                <p>{{v.aurfr_perubahan_aturan_pakai || "-"}}</p>
                                </div>
                            </td>
                            
                            </tr>
                            <tr v-if="!(data||[]).length" class="table-info">
                                <td class="text-center" colspan="99">Tidak Ada Data</td>
                            </tr>
                        </tbody>
                        </table>
                    </div>


                    <div class="form-group">
                        <table class="table table-bordered table-sm">
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Penilaian</th>
                                <th>Parameter</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1</td>
                                <td>Belum mencapai tanggal kedaluwarsa / <em>Beyond use date</em> (obat racikan)</td>
                                <td>
                                <div>
                                    {{row.auro_p1 == 'Y'?'Ya':'Tidak'}}
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Penyimpanan obat baik (masih terbungkus dalam kemasan obat)</td>
                                <td>
                                <div>
                                    {{row.auro_p2 == 'Y'?'Ya':'Tidak'}}
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Tidak ada perubahan bentuk fisik sediaan obat</td>
                                <td>
                                <div>
                                    {{row.auro_p3 == 'Y'?'Ya':'Tidak'}}                                
                                </div>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Label etiket obat terlihat jelas di masing-masing obat</td>
                                <td>
                                <div>
                                    {{row.auro_p4 == 'Y'?'Ya':'Tidak'}}                                
                                </div>
                                </td>
                            </tr>
                            <tr class="table-info">
                                <td colspan="2"><strong>Kesimpulan penilaian kelayakan obat</strong></td>
                                <td>
                                <div>
                                    {{row.auro_kesimpulan == 'L'?'Layak':'Tidak Layak'}}                                
                                </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

            </div>
            <div class="card-footer">
                <div class="text-right">
                <button @click="toList()" type="button" class="btn btn-transparent ml-auto mr-2">Back</button>
                <button v-if="!isDetail" type="submit" class="btn btn-primary">Simpan <i class="icon-paperplane ml-2"></i></button>
                <button v-if="isDetail" @click="downloadLaporan" type="button" class="btn btn-primary">Unduh Laporan <i class="icon-paperplane ml-2"></i></button>
                </div>
            </div>
            </b-form>
            </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')


export default {
  extends: GlobalVue,
  data() {
    return {
       isBtnType : '',
        isShowBtn : false,
        rowReg: {},
        data: [],
        mObat: [],
        mRacikan: [],
        mTypeSatuan: [],
        mAlatKesehatan: [],
        mObatPreferensi: [],
        mDosis: [],
        rowEdit: {},
        openAddObat: false,
        openRacikan: false,
        openFormRacikan: false,
        rowRacikan: {},
        showPatient: false,
        dataDokter: {},
        dataFarmasi: false,
        editRacikanDokter: false,
        obatLen: 0,
        currentPage: 1,
        farmTableFields: [
            { key: 'aksi', thAttr: { width: 48 } },
            { key: 'aurfr_date', label: 'Tanggal', thAttr: { width: 160 }, thStyle: {minWidth: '160px' } },
            { key: 'aurfr_nama', label: 'Nama', thAttr: { width: 320 }, thStyle: {minWidth: '320px' } },
            { key: 'aurfr_jumlah', label: 'Jumlah', thAttr: { width: 150 }, thStyle: {minWidth: '150px' } },
            { key: 'aurfr_frekuensi', label: 'Frekuensi', thAttr: { width: 210 }, thStyle: {minWidth: '210px' } },
            { key: 'aurfr_rute', label: 'Rute', thAttr: { width: 200 }, thStyle: {minWidth: '160px' }},
            { key: 'aurfr_waktu_pemberian', label: 'Waktu Pemberian', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
            { key: 'aurfr_tindak_lanjut_pemberian', label: 'Tindak Lanjut Pembarian', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
            { key: 'aurfr_perubahan_aturan_pakai', label: 'Perubahan Aturan Pakai', thAttr: { width: 200 }, thStyle: {minWidth: '160px' } },
        ],
        // rowEdit: {},
        openPanggil: false,
        canConfirm: false,
        isPanggil: false

    }
  },
  mounted(){
    this.apiGet()
    this.apiGetMaster()
    setTimeout(()=>{
        if(!(this.mRacikan||[]).length){
            this.Config.mr.configResep[2]['disabled'] = true
        }
    },1000)
    setTimeout(()=>{
        this.initSticky()
    },1000)
  },
  computed : {
      isDetail(){
        return this.$route.query.isDetail
      }
  },
  methods: {  
    downloadLaporan(){
        let data = {
            exptype: 'pdf', type: "export",  regId: this.$route.params.pageSlug        
        }
        let self = this
        self.loadingOverlay = true
        $.ajax({
            type: "POST",
            url: process.env.VUE_APP_API_URL + `/report/${'dataRekonsiliasi'}?token=IXs1029102asoaksoas102901290`,
            data: data,
            cache: false,
            xhrFields:{
                responseType: 'blob'
            },
            success: data => 
            {
                self.loadingOverlay = false
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(data)
                link.download = `resep_${self.rowReg.aur_reg_code}-${moment().format("YYYY-MM-DD")}.pdf`
                link.click()
            },
            fail: data => {
                self.loadingOverlay = false
                alert('Not downloaded')
            }
        })
    },
    autoSaveResepObat: _.debounce(function (data) {
        let dataPost = {
            type: 'auto-save-resep-obat',
            data: data
        }
        Gen.apiRest(
            "/do/" + 'UGDRekonsiliasi', {
            data: dataPost
            },
            "POST"
        )
    }, 1000),
    toList(){
        this.$router.push({ name: 'UGDRekonsiliasi' }).catch(()=>{})  
    },
    ceilData(value){
        return Math.ceil(value)
    },
    countLen(){
        setTimeout(()=>{
            this.obatLen = $('.sp-col ul li').length
        },100)
    },
    apiGetMaster(){
        let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
        Gen.apiRest(
            "/get/"+this.modulePage+
            '/master'+`?master=1&regId=${this.pageSlug}`, 
            {}
        ).then(res=>{
            _.forEach(res.data, (v,k)=>{
                this.$set(this, k, v)
            })
        })
    },
    initSticky(){
        const PageHeaderSticky = document.querySelector('.page-header-sticky')
        if(PageHeaderSticky){    
            const PageHeaderToggle = PageHeaderSticky.querySelector('.page-header-sticky-toggle')
            if($(".page-header-sticky").length && $(".page-header-sticky-toggle").length){
                PageHeaderToggle.addEventListener('click', function(e){
                // e.preventDefault()
                PageHeaderSticky.classList.toggle('page-header-sticky-open')
                if (PageHeaderSticky.classList.contains('page-header-sticky-open')) {
                    PageHeaderToggle.querySelector('span').innerText = 'Sembunyikan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-up5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-down5')
                }else{
                    PageHeaderToggle.querySelector('span').innerText = 'Tampilkan Informasi Pasien'
                    PageHeaderToggle.querySelector('i').classList.add('icon-arrow-down5')
                    PageHeaderToggle.querySelector('i').classList.remove('icon-arrow-up5')
                }
                })    
            } 
        }
    },
    getObat(val){
        let index = this.mObat.findIndex(x => x.value == val)        
        if(index !== -1){
            return this.mObat[index]['text']
        }else{
            return null
        }
    },

    
    openObat(v=null){
        let data = {
            aurfr_aur_id: this.rowReg.aur_id,
            aurfr_date: moment().format("YYYY-MM-DD"),
            aurfr_nama : null,
            aurfr_jumlah : null,
            aurfr_satuan : null,
            aurfr_frekuensi : null,
            aurfr_frekuensi_lainnya : null,
            aurfr_keterangan : null,
            aurfr_rute : null,
            aurfr_waktu_pemberian : null,
            aurfr_tindak_lanjut_pemberian : null,
            aurfr_perubahan_aturan_pakai : null,

            id : null
        }
        data.type = 'add-resep-dokter'
        Gen.apiRest(
            "/do/"+'UGDRekonsiliasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.apiGet()
            this.openAddObat = true
        })
    },
    addObat(){
        this.rowRacikan.aurfr_racikan.push({
            value : null,
            dosis : null,
            satuan_dosis : null,
            jumlah : null,
            satuan_jumlah : null,
        })
    },
    getMasterObat(data,k){
        const sldMenu = _.map(_.filter(data||[], (v2, k2)=>{
            return k2!=k
        }), v2=>{
            return v2.value
        })
        return _.filter(this.mObat, v2=>{
            return sldMenu.indexOf(v2.value)<=-1
        })
    },
    selectObat(e,k){
        let index = this.mObat.findIndex(x => x.value == e)
        if(index !== -1){
            let data = this.mObat[index]
            this.rowRacikan.aurfr_racikan[k]['dosis'] = data.dosis
            this.rowRacikan.aurfr_racikan[k]['satuan_dosis'] = data.satuan_dosis
            this.rowRacikan.aurfr_racikan[k]['satuan_jumlah'] = data.satuan            
        }
    },

    resetData(){
        this.openFormRacikan = false          
        this.apiGet()          
    },

    removeObat(v,k){
        let data = {
            type : 'remove-resep-dokter',
            id: v.aurfr_id
        }
        Gen.apiRest(
            "/do/"+'UGDRekonsiliasi',
            {data:data}, 
            "POST"
        ).then(res=>{
            this.data.splice(k,1)
            this.openAddObat = false
        })
    },
    doSubmitRacikan(){
        this.$refs['VFormRacikan'].validate().then(success=>{
            if(success){
                let data = this.rowRacikan
                let idx = this.data.findIndex(x=>x.aurfr_id == this.rowRacikan.aurfr_id)
                this.data[idx]['aurfr_satuan'] = this.rowRacikan.aurfr_satuan
                this.data[idx]['aurfr_jumlah'] = this.rowRacikan.aurfr_jumlah
                this.data[idx]['aurfr_racikan'] = this.rowRacikan.aurfr_racikan
                this.openFormRacikan = false
            }else{
                setTimeout(()=>{
                    let inv = []
                    let el = document.querySelectorAll('.label_error')
                    for(let i = 0; i < (el||[]).length; i++){
                        if(el[i].style.display !== 'none'){
                            inv.push(el[i].id)
                        }
                    }
                    if(inv.length) document.getElementById(inv[0]).scrollIntoView({behavior: 'smooth',block: 'center'})
                },500)
            }
        })
    },
    changeJenis(e,k){
         this.data[k].aurfr_frekuensi = null
         this.data[k].aurfr_frekuensi_lainnya = null
    },

    doSubmit(){

        this.$refs['VFormSubmit'].validate().then(success=>{
            if(success){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        this.loadingOverlay = true
                        let data = this.row
                        data.aur_id = this.rowReg.aur_id
                        data.type = 'submit-data'

                        Gen.apiRest(
                            "/do/"+'UGDRekonsiliasi',
                            {data:data}, 
                            "POST"
                        ).then(res=>{
                            this.loadingOverlay = false
                            let resp = res.data
                            resp.statusType = 200
                            this.$swal({
                                title: resp.message,
                                icon: resp.status,
                                confirmButtonText: 'Ok'
                            }).then(result => {
                                if (result.value) {
                                    this.toList()
                                }
                            })
                        }).catch(err=>{
                            this.loadingOverlay = false
                            if(err){
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.$parent.doSetAlertForm(err)
                        })
                    }
                })
            }
        })
    }

  },
  watch:{
    $route(){
        this.apiGet()
        this.apiGetMaster()
    },
    
    data: {
        handler(v) {
            this.autoSaveResepObat(v)
        },
        deep: true
    },
  }
}
</script>
